
import React                                       from "react";
import {FormGroup}                                 from "react-bootstrap";
import Config                                      from "../../../../config";
import ActionToolbar                               from "../../../common/toolbar/action-toolbar";
import {bindActionCreators, compose}               from "redux";
import {connect}                                   from "react-redux";
import {withStyles, Tabs, Tab}                     from "@material-ui/core";
import * as generalActions                         from "../../../../redux/general/general_actions";
import * as W09F4000Actions                        from "../../../../redux/W0X/W09F4000/W09F4000_actions";
import TabContent, {TabPanel}                      from "../../../common/tabs/tab-content";
import ButtonGeneral        from "../../../common/button/button-general";
import OrgChart             from './OrgChart';
import Filter               from "../../../filter/filter";
import './W09F4000.scss';
import '../../../libs/org-chart/orgchart-webcomponents.scss';
import ItemOrgChart         from './ItemOrgChart';
import ItemPositionChart    from "./ItemPositionChart";
import ReactDOM             from "react-dom";
import W09F4001Popup        from "./W09F4001Popup";
import Icons from "../../../common/icons/";
import {Combo} from "../../../common/form-material";
const styles = {

};
class W09F4000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            tab: 0,
            OrgChartID: "",
            DutyID: "",
            showW09F4001Popup: false,
            itemDataNode: null,
            isHiddenW09F4010: false,
            loading: false,
            isExpanded: false,
        };
        this.filter = {
            skip: 0,
            limit: 10
        };
        this.refOrgChart = null;
        this.refOrgChartPosition = null;
        this.defaultDepth = 2;
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F4000", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    componentDidMount = async () => {
       await this.loadPermission();
       this.loadCboDuty();
        this.loadListCboPosition();
    };

    loadCboDuty = () => {
        this.setState({loading: true});
        this.props.generalActions.getCboDuty2((error) => {
            this.setState({loading: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
        });
    };

    loadListCboPosition = () => {
        this.setState({loading: true});
        this.props.w09f4000Actions.getListCboPosition({Language:Config.language || "84"}, (error) => {
            this.setState({loading: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
        });
    };

    handleChange = (key,e) => {
      if(!key || !e) return false;
        switch (key) {
            case "DutyID":
                this.setState({ DutyID : e.value });
                break;
            case "OrgChartID":
                this.setState({ OrgChartID : e.value });
                break;
            default:
                break;
        }

    };

    onChangeTab = (e,value) => {
        this.setState({tab: value});
    };

    renderFilters = () => {
        const { getCboDuty, getDataCboPosition } = this.props;
        const {OrgChartID, DutyID, tab} = this.state;
            return (
                <>
                    <Filter
                        onTextChanged={this.onSearch}
                        placeholder={Config.lang("DHR_Noi_dung_tim_kiem")}
                        renderFilter={() => {
                            return (
                                <>
                                    {tab === 1 && (
                                        <FormGroup>
                                            <Combo
                                                dataSource={Config.storeDataSoureDevExtreme(getCboDuty)}
                                                showClearButton={true}
                                                displayExpr={'DutyName'}
                                                valueExpr={"DutyID"}
                                                stylingMode={'outlined'}
                                                label={Config.lang('DHR_Chuc_vu')}
                                                value={DutyID}
                                                onValueChanged={(e) => this.handleChange("DutyID", e)}
                                            />
                                        </FormGroup>
                                    )}
                                    {tab === 0 && (
                                        <FormGroup>
                                            <Combo
                                                dataSource={Config.storeDataSoureDevExtreme(getDataCboPosition)}
                                                showClearButton={true}
                                                displayExpr={'OrgChartName'}
                                                valueExpr={"OrgChartID"}
                                                stylingMode={'outlined'}
                                                label={Config.lang('DHR_Co_cau_to_chuc')}
                                                value={OrgChartID}
                                                onValueChanged={(e) => this.handleChange("OrgChartID", e)}
                                            />
                                        </FormGroup>
                                    )}

                                    <FormGroup className={"display_row align-center valign-middle"}>
                                        <ButtonGeneral
                                            name={Config.lang("DHR_Tim_kiem")}
                                            typeButton={"search"}
                                            size={"large"}
                                            color={"primary"}
                                            variant={"outlined"}
                                            onClick={this.loadDataFormOrgChart}
                                        />
                                    </FormGroup>
                                </>
                            );
                        }}
                    />
                </>
            )
    };

    onRefesh = (depth) => {
        const {tab} = this.state;
        if (tab === 1) {
            if (this.refOrgChartPosition) {
                this.setState({DutyID: []}, () => {
                    this.refOrgChartPosition.refreshChart(depth);
                });
            }
        } else {
            if (this.refOrgChart) {
                this.setState({OrgChartID: []}, () => {
                    this.refOrgChart.refreshChart(depth);
                });
            }
        }

        this.setState({isExpanded: !this.state.isExpanded}, () => {
            if(depth === 2) {this.setState({isExpanded: false})}
        });
    };

    renderItemOrgChart = (node, data, orgchart) => {
        const child = React.createElement(ItemOrgChart, {
            node: node,
            data: data,
            onW09F4001Popup: this.showW09F4001Popup,
            orgchart:    orgchart,
        }, null);
        ReactDOM.render(child, node);
    };

    renderItemOrgChartPostion = (node, data, orgchart) => {
        const child = React.createElement(ItemPositionChart, {
            node: node,
            data: data,
            onW09F4001Popup: this.showW09F4001Popup,
            orgchart:    orgchart,
        }, null);
        ReactDOM.render(child, node);
    };

    showW09F4001Popup = (data) => {
        const {tab} = this.state;
        this.setState({
            isHiddenW09F4010: tab === 1,
            itemDataNode: data,
            showW09F4001Popup: true
        });
    };
    onCloseW09F4001Popup = () => {
        this.setState({
            showW09F4001Popup: false
        });
    };

    loadDataFormOrgChart = () => {
        const {tab} = this.state;
        if (tab === 1) {
            if (this.refOrgChartPosition) {
                this.refOrgChartPosition.refreshChart();
            }
        } else {
            if (this.refOrgChart) {
                this.refOrgChart.refreshChart();
            }
        }
    };

    render() {
        const {iPermission, tab, OrgChartID, showW09F4001Popup, itemDataNode, isHiddenW09F4010, isExpanded, DutyID} = this.state;
        if (!iPermission) return null;

        return (
            <React.Fragment>
                <div className={"hidden"}>{this.renderFilters()}</div>

                {showW09F4001Popup && (
                    <W09F4001Popup
                        open={showW09F4001Popup}
                        title={Config.lang("DHR_Danh_sach_nhan_vien")}
                        data={itemDataNode}
                        isHiddenW09F4010={isHiddenW09F4010}
                        onClose={this.onCloseW09F4001Popup}
                    />
                )}
                <FormGroup>
                    <ActionToolbar
                    >
                        <div className={"display_row align-center align-between"} style={{width: '100%'}}>
                            <div className={"display_row align-left"}>
                                <Tabs
                                    indicatorColor={"primary"}
                                    textColor={"primary"}
                                    value={tab}
                                    onChange={this.onChangeTab}
                                    // className={classes.tabs}
                                >
                                    <Tab style={{minWidth: 70, minHeight: 42}} value={0} label={Config.lang("DHR_Co_cau_to_chuc")} />
                                    <Tab style={{minWidth: 70, minHeight: 42}} value={1} label={Config.lang("DHR_Co_cau_chuc_danh")} />
                                </Tabs>
                            </div>

                            <div className={"display_row align-center valign-bottom "}>
                                <ButtonGeneral
                                    variant={"contained"}
                                    color={"primary"}
                                    name={Config.lang("DHR_Lam_Moi")}
                                    icon={<Icons className="fas fa-sync" style={{fontSize:14}}/>}
                                    style={{textTransform: "uppercase"}}
                                    size={"medium"}
                                    onClick={() => this.onRefesh(this.defaultDepth)}
                                />
                                <ButtonGeneral
                                    variant={"contained"}
                                    className={"mgl10"}
                                    color={"primary"}
                                    name={ isExpanded ? Config.lang("DHR_Thu_gon")  : Config.lang("DHR_Mo_tat_ca")}
                                    icon={<Icons className={ !isExpanded ? "fas fa-expand-arrows-alt" : "" } src={isExpanded ? require('../../../common/icons/_icons/compress-arrows-alt-solid.svg') : ""} height={14} width={14} style={{fontSize:14}}/>}
                                    style={{textTransform: "uppercase"}}
                                    size={"medium"}
                                    onClick={() => this.onRefesh(isExpanded ? this.defaultDepth : "")}
                                />
                            </div>
                        </div>
                    </ActionToolbar>
                </FormGroup>
                <TabContent
                    style={{backgroundColor: "#F4F4F4"}}
                    activeKey={tab}
                    disableSwipe={true}
                    lazyLoading={false}
                    // className={classes.tabContent}
                >
                   <TabPanel index={0}>
                       <OrgChart
                           ref={ref => this.refOrgChart = ref}
                           FormID={"W09F4000"}
                           id={"org-chart-container"}
                           depth={this.defaultDepth}
                           valueExpr={"OrgChartID"}
                           displayExpr={"OrgChartName"}
                           parentIDExpr={"OrgChartParentID"}
                           renderItem={this.renderItemOrgChart}
                           tabID={tab}
                           search={OrgChartID}/>
                    </TabPanel>
                    <TabPanel index={1}>
                        <OrgChart
                            ref={ref => this.refOrgChartPosition = ref}
                            FormID={"W09F4010"}
                            id={"position-chart-container"}
                            depth={this.defaultDepth}
                            valueExpr={"DutyID"}
                            displayExpr={"DutyName"}
                            parentIDExpr={"DutyManagerID"}
                            renderItem={this.renderItemOrgChartPostion}
                            tabID={tab}
                            search={DutyID}/>
                    </TabPanel>
                </TabContent>
            </React.Fragment>
        );
    }

}

export default compose(connect(state => ({
        getCboDuty: state.general.getCboDuty2,
        getDataCboPosition: state.W09F4000.getDataCboPosition,
    }),
    (dispatch) => ({
        w09f4000Actions: bindActionCreators(W09F4000Actions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
    })
), withStyles(styles, {withTheme: true}))(W09F4000);
