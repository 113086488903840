import React, {Component} from 'react';
import Tooltip from "../../../common/tooltip/tooltip";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import {
    Tab,
    Tabs,
    Icon,
    IconButton,
    Badge,
} from "@material-ui/core";
import {Accordion, AccordionSummary, AccordionDetails} from 'diginet-core-ui/components';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import _ from "lodash";
import {FormGroup} from "reactstrap";
import {Image} from "react-bootstrap";
import moment from "moment";
import {browserHistory} from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as mainActions from "../../../../redux/main/main_actions";

const styles = theme => {
    return ({
        iconCursor: {
            borderRadius: 5,
            padding: 10,
            color: "#575757"
        },
        tooltipReminder: {
            // transform: "translateX(60%)!important",
            [theme.breakpoints.down('sm')]: {
                transform: "none",
            },
            "&.popup-notify": {
                "&:after": {
                    borderBottomColor: theme.palette.info.main
                },
                "&.popup-tooltip-inner": {
                    [theme.breakpoints.up('sm')]: {
                        width: 455,
                    }
                }
            },
            "& .form-group": {
                "& .header-tabs-notify.MuiTabs-root": {
                    margin: "0 20px",
                    height: 42,
                    minHeight: 0,
                }
            }

        },
        colorDanger: {
            color: theme.palette.danger.main
        },
        tab: {
            "&:after": {
                content: "none"
            },
            "&.MuiTab-root": {
                lineHeight: 1.25
            }
        },
        headerTitleReminder: {
            backgroundColor: theme.palette.info.main
        },
        marquee: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            "& > span": {
                display: "inline-block",
                width: "100%",
                "& > span": {
                    position: "relative",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    left: 0,
                },
            },
            "&:active, &:hover": {
                "& > span": {
                    width: "auto",
                    "& > span": {
                        left: "calc(284px - 100%)",
                        "&.voucher-content": {
                            left: "calc(279px - 100%)"
                        },
                    },
                }
            }
        },
        accordion: {
            margin: "0px 20px",
            "& .DGN-UI-Accordion": {
                minHeight: 43,
                padding: 0,
                borderBottom: "1px solid #9597A1",
                boxShadow: "none",
                "& .css-1eud3ib": {
                    width: "100%"
                }
            },
        },
        voucherName: {
            fontSize: "1.1rem",
            fontWeight: 600,
            width: "30%",
            "& > label": {
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
            }
        },
        imageIcon: {
            minWidth: "25px !important",
            minHeight: "25px !important",
            width: "25px !important",
            height: "25px !important",
            marginRight: "0 !important"
        },
        accordionSummaryHeaderTitle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            color: theme.palette.info.main,
            "& .material-icons": {
                fontSize: "1rem",
                marginRight: 6
            },
            fontWeight: 600,
            fontSize: 16
        },
        accordionDetailContent: {
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: "100%",
            "&:hover": {
                backgroundColor: "#F4F5F7"
            },
            padding: "12px 16px 4px",
            margin: "4px 0px"
        },
        flexCol: {
            display: "flex",
            flexDirection: "column",
        },
        iconInfoColor: {
            color: theme.palette.info.main
        }
    })
};

class W09F7000 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenReminder: false,
            loadingReminderMaster: false,
            loadingReminderDetails: {},
            tabValue: "",
            expanded: {},
        };
        this.scrollTypeReminder = 0;
        this.paramsLoadReminder = {
            Master: {
                limit: 20,
                skip: 0
            },
            Details: {
                limit: 10,
                skip: 0,
                AlertBaseID: ""
            },
        };
    };

    getWidthText = (text = "", fontSize = 0) => {
        this.element = document.createElement('canvas');
        this.context = this.element.getContext('2d');
        this.context.font = `${fontSize}px  Nunito sans-serif`;
        return this.context.measureText(text).width;
    };

    handleScroll = (e, type) => {
        const {dataListReminderMaster, dataListReminderDetails} = this.props;
        const target = e.target;
        const typeLoadReminder = ["Master", "Details"];
        const getTypeReminder = typeLoadReminder[type];
        const getDataListReminder = !!type ? dataListReminderDetails : dataListReminderMaster;
        if (_.eq(_.ceil(target.scrollHeight - target.scrollTop), target.clientHeight)) {
            if (!_.gte(_.size(getDataListReminder.rows), getDataListReminder.total)) {
                this.paramsLoadReminder[getTypeReminder].skip += this.paramsLoadReminder[getTypeReminder].limit;
                this.loadListReminder(type);
            }
        }
    };

    loadListReminder = (type = 0) => {
        const {tabValue, expanded} = this.state;
        const {Details} = this.paramsLoadReminder;
        const typeLoadReminder = ["Master", "Details"];
        const getAlertBaseIDCurrent = _.get(Details, "AlertBaseID", "");
        const getTypeReminder = typeLoadReminder[type];
        const keyLoading = `loadingReminder${getTypeReminder}`;
        const apiActions = `getListReminder${getTypeReminder}`;
        // sort: JSON.stringify([{"read": "ASC"}, {"createdAt": "DESC"}, {"updatedAt": "DESC"}, {"clearBadge": "ASC"}]),;
        this.setState({[keyLoading]: !!type ? {[getAlertBaseIDCurrent]: true} : true});
        this.props.mainActions[apiActions](
            _.lowerCase(tabValue),
            this.paramsLoadReminder[getTypeReminder],
            (err) => {
                if (!!type) this.setState({expanded: {...expanded, [getAlertBaseIDCurrent]: true}});
                setTimeout(() => {
                    this.setState({[keyLoading]: !!type ? {[getAlertBaseIDCurrent]: false} : false});
                }, 250);
                if (err) {
                    Config.popup.show("ERROR", err);
                    return false;
                }
            })
    };

    onClickIgnoreReminder = (dataParams) => {
        this.props.mainActions.ignoreReminder(dataParams, (err) => {
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            Config.notify.show("success", Config.lang("Bo_qua_thong_bao_thanh_cong"), 3000);
            this.props.mainActions.setReminderDetailsEmpty();
            this.loadListReminder(1);
        })
    };

    onClickVoucherAlert = (data) => {
        const {toggleMenuESS} = this.props;
        toggleMenuESS && toggleMenuESS(Number(data.Type ?? 0), false);
        browserHistory.push({
            pathname: Config.getRootPath() + _.get(data, "FormID", ""),
            state: {voucher_id: _.get(data, "KeyID", "")}
        });
    };

    onChangeTab = (_, tabValue) => {
        this.paramsLoadReminder = {
            Master: {
                limit: 20,
                skip: 0
            },
            Details: {
                limit: 10,
                skip: 0,
                AlertBaseID: ""
            },
        };
        this.setState({tabValue, expanded: {}}, () => {
            this.props.mainActions.setReminderDetailsEmpty();
            this.props.mainActions.setReminderMasterEmpty();
            this.loadListReminder();
        });

    };

    onClickExpand = (AlertBaseID = "") => {
        const {expanded} = this.state;
        const {Details} = this.paramsLoadReminder;
        if (Details.AlertBaseID !== AlertBaseID) {
            this.paramsLoadReminder.Details = {
                skip: 0,
                limit: 10,
                AlertBaseID,
            };
            this.setState({expanded: {..._.mapValues(expanded, () => false), [AlertBaseID]: true}}, () => {
                setTimeout(() => {
                    this.props.mainActions.setReminderDetailsEmpty();
                    this.loadListReminder(1);
                }, 80)
            });
        } else {
            this.setState({expanded: {...expanded, [AlertBaseID]: !expanded[AlertBaseID]}})
        }
    };

    render() {
        const {isOpenReminder, tabValue, loadingReminderMaster, loadingReminderDetails, expanded} = this.state;
        const {classes, dataListReminderMaster, dataListReminderDetails} = this.props;
        const speedMq = 245;
        return (
            <Tooltip
                onEntered={e => {
                    e.style.top = (document.documentElement.scrollTop + e.offsetTop);
                    document.documentElement.style.overflow = "hidden";
                    this.setState({isOpenReminder: true}, () => {
                        const {Master} = this.paramsLoadReminder;
                        if (dataListReminderMaster.total > 0) {
                            Master.skip = 0;
                            Master.limit = 20;
                            this.loadListReminder()
                        }
                    })
                }}
                onExited={() => {
                    this.setState({isOpenReminder: false})
                }}
                renderItem={() => (
                    <>
                        <ActionToolbar
                            style={{marginTop: -5}}
                            className={classes.headerTitleReminder}
                            alignment={"space-between"}
                            height={48}

                            onBack={this.onBack}
                        >
                                <span style={{color: "#FFF", paddingLeft: 20}}
                                      className={"action-toolbar-title header-title-notify"}>{Config.lang("Nhac_nho1")}</span>
                        </ActionToolbar>
                        <FormGroup style={{borderBottom: "1px solid #D4D5D9"}}>
                            <Tabs
                                indicatorColor={"primary"}
                                textColor={"primary"}
                                className={"header-tabs-notify"}
                                value={tabValue}
                                onChange={this.onChangeTab}
                            >
                                <Tab disabled={loadingReminderMaster}
                                     className={classes.tab}
                                     style={{minWidth: 45}}
                                     value={""}
                                     label={Config.lang("Tat_ca")}/>
                                <Tab disabled={loadingReminderMaster}
                                     className={classes.tab}
                                     style={{minWidth: 45}}
                                     value={"TODAY"}
                                     label={Config.lang("Hom_nay")}/>
                                <Tab disabled={loadingReminderMaster}
                                     className={classes.tab}
                                     style={{minWidth: 45}}
                                     value={"DELAYS"}
                                     label={Config.lang("Tre_han")}/>
                            </Tabs>
                        </FormGroup>
                        <FormGroup>
                            <div
                                onScroll={(e) => {
                                    if (!_.includes(expanded, true)) {
                                        this.handleScroll(e, 0);
                                    }
                                }}
                                style={{maxHeight: 395, overflowY: "scroll"}}>
                                {loadingReminderMaster && (<div style={{zIndex: 99}} className={"wrapper-loading"}>
                                        <CircularProgress color="primary"/>
                                    </div>
                                )}
                                <div className={classes.accordion}>
                                    {_.size(dataListReminderMaster.rows) > 0 ? _.map(dataListReminderMaster.rows, (item, i) => {
                                            const getAlertBaseID = _.get(item, "AlertBaseID", "");
                                            const getAlertBaseName = _.get(item, "AlertBaseName", "");
                                            const getTotalDetail = _.get(item, "totalDetail", 0);
                                            const loadingListReminderDetails = _.get(loadingReminderDetails, `${getAlertBaseID}`, false);
                                            const getTextWidthTitleMaster = this.getWidthText(getAlertBaseName, 14);
                                            const stopMarqueeMaster = _.gte(getTextWidthTitleMaster, 284) ? {} : {left: 0};
                                            const timeTakenMqMaster = Math.round(getTextWidthTitleMaster / speedMq);
                                            return (
                                                <Accordion
                                                    disabled={loadingListReminderDetails}
                                                    expand={_.get(expanded, `${getAlertBaseID}`, false)}
                                                    onClick={() => this.onClickExpand(getAlertBaseID)}
                                                    key={i}>
                                                    <AccordionSummary
                                                        expandIconAt={"end"}
                                                        expandIcon={<ExpandMoreIcon fontSize={"small"}/>}>
                                                        <div className={classes.accordionSummaryHeaderTitle}>
                                                            <Image
                                                                className={classes.imageIcon}
                                                                src={require("../../../../assets/images/header/bars.svg")}/>
                                                            <div style={{position: "relative", width: "86%"}}>
                                                                <div
                                                                    style={{position: "absolute", top: -10, width: "100%"}}>
                                                                    <div className={classes.marquee} style={{width: 284}}>
                                                                        <span>
                                                                            <span
                                                                                style={{fontSize: "1rem",  transition: `left ${timeTakenMqMaster}s linear`, ...stopMarqueeMaster}}>{getAlertBaseName}</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{width: "10%"}}>{`(${getTotalDetail})`}</div>
                                                            <Image className={classes.imageIcon}
                                                                   src={require('../../../../assets/images/general/logodhr.svg')}/>
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{padding: 0, justifyContent: "center"}}>
                                                        <div
                                                            onScroll={(e) => this.handleScroll(e, 1)}
                                                            style={{
                                                                maxHeight: 270,
                                                                overflowY: "scroll",
                                                                overflowX: "hidden"
                                                            }}>
                                                            {loadingListReminderDetails &&
                                                            <div style={{zIndex: 99}} className={"wrapper-loading"}>
                                                                <CircularProgress color="primary"/>
                                                            </div>}
                                                            <div className={classes.flexCol} style={{width: "100%"}}>
                                                                {_.size(dataListReminderDetails.rows) > 0 ? _.map(dataListReminderDetails.rows, (itemAlert, iAlert) => {
                                                                        const voucherDes = _.get(itemAlert, "VoucherDes", "");
                                                                        const voucherNo = _.get(itemAlert, "VoucherNo", "");
                                                                        const voucher = _.join(_.compact([voucherDes, voucherNo]), ": ");
                                                                        const validDate = moment(_.get(itemAlert, "ValidDate", moment())).utc();
                                                                        const isOutOfDate = moment().isAfter(validDate);
                                                                        const getTextWidthTitleDetails = this.getWidthText(voucher, 15.4);
                                                                        const stopMarqueeDetails = _.gte(getTextWidthTitleDetails, 279) ? {} : {left: 0};
                                                                        const timeTakenMqDetails = Math.round(getTextWidthTitleDetails / speedMq);
                                                                        return (
                                                                            <div key={iAlert}
                                                                                 className={classes.accordionDetailContent}>
                                                                                <Icon style={{
                                                                                    marginTop: 6,
                                                                                    marginRight: 8,
                                                                                    fontSize: "0.6rem"
                                                                                }} className="fas fa-circle"/>
                                                                                <div style={{
                                                                                    position: "relative",
                                                                                    width: "86%"
                                                                                }}>
                                                                                    <div style={{position: "absolute"}}>
                                                                                        <div style={{cursor: "pointer"}}
                                                                                             onClick={() => this.onClickVoucherAlert(itemAlert)}
                                                                                             className={classes.flexCol}>
                                                                                            <div style={{width: 279}}
                                                                                                 className={classes.marquee}>
                                                                                         <span>
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: "1rem",
                                                                                                        fontWeight: 500,
                                                                                                        color: "#151A30",
                                                                                                        transition: `left ${timeTakenMqDetails}s linear`,
                                                                                                        ...stopMarqueeDetails
                                                                                                    }}
                                                                                                    className={'voucher-content'}
                                                                                                >
                                                                                                    {voucher}
                                                                                                </span>
                                                                                         </span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <label
                                                                                                    style={{fontSize: "0.8rem"}}
                                                                                                    className={isOutOfDate ? classes.colorDanger : ""}>{validDate.format("DD/MM/YYYY kk:mm:ss")}</label>
                                                                                                {isOutOfDate ? <>
                                                                                                    <label style={{
                                                                                                        color: "#7F828E",
                                                                                                        margin: "0px 6px"
                                                                                                    }}>•</label>
                                                                                                    <span style={{
                                                                                                        color: "#7F828E",
                                                                                                        fontSize: "0.8rem"
                                                                                                    }}>{Config.lang("Qua_han")}</span>
                                                                                                </> : ""}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{margin: "-6px 2px"}}
                                                                                     className={classes.flexCol}>
                                                                                    <IconButton size={"small"}
                                                                                                onClick={() => this.onClickIgnoreReminder(itemAlert)}>
                                                                                        <Image style={{padding: 5}}
                                                                                               className={classes.imageIcon}
                                                                                               src={require("../../../../assets/images/header/space-back.svg")}/>
                                                                                    </IconButton>
                                                                                    <label
                                                                                        style={{
                                                                                            color: "#7F828E",
                                                                                            fontSize: "0.65rem",
                                                                                            fontWeight: 600
                                                                                        }}>
                                                                                        {Config.lang("Bo_qua")}
                                                                                    </label>
                                                                                </div>
                                                                                <div>
                                                                                    <ChevronRightIcon
                                                                                        style={{marginBottom: 16}}/>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    : !loadingListReminderDetails ?
                                                                        <span style={{padding: "13px 0px"}}
                                                                              className={"maxline-two text-muted"}>
                                                                            {Config.lang("Khong_co_thong_bao_nhac_nho_nao")}
                                                                        </span> : <div style={{margin: "35px 0px"}}/>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={"display_row align-center valign-middle"}>
                                                            <ExpandMoreIcon
                                                                color={!_.gte(_.size(dataListReminderDetails.rows), dataListReminderDetails.total) ? "action" : "disabled"}/>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>)
                                        }
                                    ) : <div style={{width: "100%", textAlign: "center"}}>
                                        <span
                                            className={"maxline-two text-muted"}>{Config.lang("Khong_co_thong_bao_nhac_nho_nao")}</span>
                                    </div>
                                    }
                                </div>
                            </div>
                        </FormGroup>
                        {!_.includes(expanded, true) && <div className={"display_row align-center valign-middle"}>
                            <ExpandMoreIcon
                                color={!_.gte(_.size(dataListReminderMaster.rows), dataListReminderMaster.total) ? "action" : "disabled"}/>
                        </div>}
                    </>
                )}
                renderCursor={() => {
                    return (<IconButton classes={{root: classes.iconCursor}}>
                        <Badge invisible={dataListReminderMaster.total < 1} color="error"
                               variant="dot">
                            <Icon classes={{colorPrimary: classes.iconInfoColor}}
                                  color={isOpenReminder ? "primary" : "inherit"}
                                  className={"fas fa-clipboard-check"}/>
                        </Badge>
                    </IconButton>)
                }}
                className={_.join([classes.tooltipReminder, "popup-notify popup-tooltip"], " ")}
            />
        )
    };

    componentDidMount() {
        this.loadListReminder();
    };
}


export default compose(connect(
    state => ({
        dataListReminderMaster: state.main.dataListReminderMaster,
        dataListReminderDetails: state.main.dataListReminderDetails,
    }),
    dispatch => ({
        mainActions: bindActionCreators(mainActions, dispatch)
    })), withStyles(styles, {withTheme: true}))(W09F7000);
