/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/5/2021
 * @Example
 */

import React, {useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import {IconButton, makeStyles} from "@material-ui/core";
import Config from "../../../../../config";
import * as _ from "lodash";
import Grid from "@material-ui/core/Grid";
import UserImage from "../../../../common/user/user-image";
import Icons from "../../../../common/icons";
import ButtonGeneral from "../../../../common/button/button-general";
import Popover from "../../../../../components/grid-container/popover-action";
import {Key} from "diginet-core-ui/icons";

const useStyles = makeStyles(() => ({
    divItemMember: {
        background: '#FFFFFF',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        padding: 15,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        minHeight: 180,
        position: 'relative',
        '& .divItemMember_key': {
            position: 'absolute',
            top: 5,
            left: 5,
            "&.primary_key": {}
        },
        '& .divItemMember_button': {
            display: 'none',
            position: 'absolute',
            top: 5,
            right: 5
        },
        '&:hover .divItemMember_button': {
            display: 'block'
        }
    }
}));

const W09F3010Member = React.memo((props) => {

    const classes = useStyles();
    const {data, members, onAction} = props;
    const [selectedMember, setSelectedMember] = useState(null);
    const refAction = useRef(null);

    const isAdmin = useMemo(() => _.get(data, "IsAdmin", 0), [data]);
    const listMembers = members || [];

    const onShowAction = (e, data) => {
        if (!e) return false;
        setSelectedMember(data);
        if (refAction && refAction.current) refAction.current.instance.show(e.target);
    };

    const _onAction = (key, data) => {
        if (refAction && refAction.current) refAction.current.instance.hide();
        if (onAction) onAction(key, data);

    };

    const getMenuRole = (role) => {
        if (_.isNull(role)) return "";
        const numberRole = _.toNumber(role);
        let menuRole = [];
        if (numberRole !== 0) {
            menuRole = [...menuRole, {name: Config.lang("Chi_dinh_lam_thanh_vien"), role: 0}];
        }
        if (numberRole !== 1) {
            menuRole = [...menuRole, {name: Config.lang("Chi_dinh_lam_quan_tri_vien"), role: 1}];
        }
        if (numberRole !== 2) {
            menuRole = [...menuRole, {name: Config.lang("Chi_dinh_lam_thanh_vien_vai_tro_quan_tri"), role: 2}]
        }
        return menuRole;
    };

    const menuRole = getMenuRole(_.get(selectedMember, "Role", null));

    return (
        <>
            {Boolean(isAdmin) && <Popover
                reference={refAction}
                width="auto"
                position="bottom"
                shading={false}
            >
                <div className={"display_col align-left"} style={{padding: "5px 10px"}}>
                    {_.map(menuRole, (item, i) => <ButtonGeneral
                        key={i}
                        name={item.name}
                        variant={"custom"}
                        size={"small"}
                        style={{
                            width: '100%',
                            justifyContent: 'flex-start'
                        }}
                        onClick={() => _onAction('isAdmin', {...selectedMember, newRole: item.role})}
                    />)}
                    <ButtonGeneral
                        name={Config.lang('DHR_Xoa_khoi_nhom')}
                        variant={"custom"}
                        size={"small"}
                        style={{
                            width: '100%',
                            justifyContent: 'flex-start'
                        }}
                        className={'valign-top'}
                        onClick={() => _onAction('removeFromGroup', selectedMember)}
                    />
                </div>
            </Popover>}
            {listMembers && listMembers.length > 0 && <Grid
                container
                spacing={2}
                direction="row"
                alignItems="center"
            >
                {listMembers && listMembers.map((member, idx) => {
                    const isHide = isAdmin !== 1 || Config.profile.UserID === _.get(member, "MemberID", "");
                    const user = Config.getUser({UserID: _.get(member, "MemberID", "")});
                    const memberRole = _.get(member,"Role", 0);
                    return <Grid key={idx} item xs={6} sm={4} md={3} lg={2}>
                        <div className={classes.divItemMember}>
                            {!!memberRole && <div className={"divItemMember_key"}>
                                <Key
                                    color={memberRole === 1 ? "#000000" : "#FFC700"}
                                    height="22"
                                    width="22"
                                />
                            </div>}
                            <UserImage
                                key={idx}
                                style={{
                                    marginBottom: 15,
                                    marginRight: 0
                                }}
                                data={member}
                                keyExpr={"MemberID"}
                                valueExpr={"UserID"}
                                width={72} height={72}
                            />
                            <div>{Config.sub_text(_.get(member, "UserName", _.get(user, "UserName", "")), 25)}</div>
                            <div className={"text-muted"}>{_.get(member, "MemberID", "")}</div>
                            <div className={"divItemMember_button"}>
                                {!isHide &&
                                <IconButton size={"small"} disabled={false} onClick={(e) => onShowAction(e, member)}>
                                    <Icons name={"biggroup"}/>
                                </IconButton>}
                            </div>
                        </div>
                    </Grid>
                })}
            </Grid>}
            {listMembers && listMembers.length <= 0 &&
            <div className={"mgt15 mgb15"} style={{textAlign: "center"}}>{Config.lang("DHR_Khong_co_du_lieu")}</div>}
        </>
    );
}, (prevProps, nextProps) => {
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
        && JSON.stringify(prevProps.members) === JSON.stringify(nextProps.members);
});

W09F3010Member.propTypes = {
    data: PropTypes.object,
    members: PropTypes.array,

    onAction: PropTypes.func
};

export default W09F3010Member;
