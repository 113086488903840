import React, { Component } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles, Tabs, Tab, Button, Icon } from "@material-ui/core";
const styles = (theme) => {
    return {
        root: {
            minWidth: '200px',
            maxWidth: '100%',
            width: '100%',
            height: '35px',
            float: 'left',
            paddingLeft: '-15px'
        },
        tab: {
            backgroundColor: '#F9F9F9',
            height: "100%",
            overflow: "auto",
        },
        selected: {
            backgroundColor: 'white',
        },
        wrapper: {
            flexDirection: "row",
            justifyContent: "start",
        },
        rootTab: {
            // width: "240px",
            minHeight: '42px'
        },
        iconStyle: {
            marginRight: '22px',
            width: '16px',
            height: '16px'
        },
        bgIndicator: {
            backgroundColor: "transparent",
        },
        childrenTab: {
            display: 'flex',
            width: "100%",
            alignItems: 'center',
            justifyContent: 'center'
        },
    };
};

class W09F9000ManualLeft extends Component {
    shouldComponentUpdate = (nextProps) => nextProps.tabIndex !== this.props.tabIndex || nextProps.classes !== this.props.classes

    handleChange = (evt, value) => {
        this.props.handleChangeTab(value);
    };

    renderButtonTab = (e) => {
        return (
            e.visible && <Button variant={'outlined'} color={'default'} onClick={() => e.onClick()}
                style={{ minWidth: 20, padding: 12 }}>
                <Icon className={e.direction === 'left' ? 'fas fa-chevron-left' : 'fas fa-chevron-right'} />
            </Button>
        );
    };

    renderTab = () => {
        const { classes, getManualLeftPanel } = this.props;
        return getManualLeftPanel.map((item, key) => <Tab
            key={key}
            value={item.GroupID}
            className={classes.root}
            classes={{ root: classes.rootTab, selected: classes.selected, wrapper: classes.wrapper }}
            label={<div className={classes.childrenTab}>
                <div style={{ display: 'flex' }}>
                    <div style={{ fontSize: "1rem" }}>
                        {item.GroupDes}
                    </div>
                </div>
            </div>}
        />)
    }

    render() {
        const { classes, tabIndex } = this.props;
        return (
            <Tabs
                value={tabIndex}
                onChange={this.handleChange}
                indicatorColor='primary'
                textColor="primary"
                style={{ fontSize: '16px'}}
                aria-label="Vertical tabs example"
                ScrollButtonComponent={this.renderButtonTab}
                orientation="vertical"
                className={classes.tab}
                classes={{ indicator: classes.bgIndicator }}
            >
                {this.renderTab()}
            </Tabs>
        )
    }
}


export default compose(connect((state) => ({
    getManualLeftPanel: state.W09F9000.getManualLeftPanel,
})), withStyles(styles))(W09F9000ManualLeft);
