import React, { Component } from 'react'
import { Column, RequiredRule, CustomRule } from "devextreme-react/data-grid";
import { connect } from "react-redux";
import { IconButton, Link, InputBase } from "@material-ui/core";
import DoneIcon from '@material-ui/icons/Done';
import UndoIcon from '@material-ui/icons/Undo';
import _ from "lodash";
import Config from "../../../../config";
import GridContainer from "../../../grid-container/grid-container";
import Icons from "../../../common/icons/";
import GridActionBar from "../../../grid-container/grid-actionbar";
class W09F9000ManualRight extends Component {

    shouldComponentUpdate = nextProps => nextProps.groupID !== this.props.groupID || nextProps.gridLoading !== this.props.gridLoading || nextProps.searchValue !== this.props.searchValue || nextProps.isEditing !== this.props.isEditing;

    onInitNewRow = e => {
        const { getManualRightPanel } = this.props;
        e.data.OrderNo = _.get(_.maxBy(getManualRightPanel, v => v.OrderNo), "OrderNo", 0) + 1;
    }

    renderButtonAction = e => {
        const { isPermission, onEditRow, onNewRow } = this.props;
        return (
            <GridActionBar>
                {!e.row.isEditing ?
                    <div className="">
                        <IconButton
                            disabled={isPermission <= 1}
                            aria-label="view"
                            style={{ padding: 0, width: 30, height: 30, marginRight: 4 }}
                            onClick={() => onEditRow(e, "edit")}
                        >
                            <Icons name={"edit"}/>
                        </IconButton>
                        <IconButton
                            disabled={isPermission <= 1}
                            aria-label="delete"
                            style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
                            onClick={() => onEditRow(e, "delete")}
                        >
                            <Icons name={"delete"}/>
                        </IconButton>
                    </div>
                    :
                    <div className="show-btn-action">
                        <IconButton aria-label="view" style={{ padding: 0, width: 30, height: 30, marginRight: 4 }}
                            onClick={() => { e.component.saveEditData(); onNewRow(); }}>
                            <DoneIcon fontSize="small" />
                        </IconButton>
                        <IconButton aria-label="view" style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
                            onClick={() => { e.component.cancelEditData(); onNewRow(); }}>
                            <UndoIcon fontSize="small" />
                        </IconButton>
                    </div>
                }
            </GridActionBar>
        );
    };

    renderItemDescriptions = e => {
        return <InputBase
            readOnly
            autoFocus={false}
            multiline={true}
            fullWidth={true}
            value={e.data.ItemDes}
        />
    }

    renderUrlRedirect = e => {
        const link = _.get(e, "data.URL", "#")
        return (<Link color="primary" href={link} target="_blank">
            {link}
        </Link>)
    }

    render() {
        const { getManualRightPanel, onRowUpdated, refDataRow, gridLoading, searchValue, checkUrl } = this.props;
        const dataFilter = getManualRightPanel.filter(v => v.ItemDes.toUpperCase().includes(searchValue.toUpperCase())).map(filteredItemDes => filteredItemDes);
        return (
            <GridContainer
                onInitNewRow={this.onInitNewRow}
                showBorders={false}
                style={{ border: 0, width: "100%"}}
                loading={gridLoading}
                reference={ref => refDataRow(ref)}
                dataSource={dataFilter}
                columnAutoWidth={true}
                height={600}
                editing={{
                    texts: {
                        confirmDeleteMessage: ""
                    },
                }}
                selection={{ mode: 'row' }}
                hoverStateEnabled={true}
                typeShort={true}
                onRowInserted={onRowUpdated}
                onRowUpdated={onRowUpdated}
                showColumnHeaders={false}
            >
                <Column
                    width={110}
                    alignment={"center"}
                    visible={Config.isMobile}
                    allowEditing={false}
                    cellRender={this.renderButtonAction}
                />
                <Column
                    alignment={"left"}
                    allowEditing={false}
                    dataField={'OrderNo'}
                    width={50}
                />
                <Column
                    dataField={'ItemDes'}
                    minWidth={300}
                    cellRender={this.renderItemDescriptions}
                >
                    <RequiredRule message={Config.lang("Truong_nay_bat_buoc_nhap")} />
                </Column>
                <Column
                    dataField={'URL'}
                    width={450}
                    cellRender={this.renderUrlRedirect}
                >
                    <RequiredRule />
                    <CustomRule
                        message={Config.lang("DHR_URL_khong_hop_le")}
                        validationCallback={checkUrl}
                    />
                </Column>
                <Column
                    // type="buttons"
                    alignment={"center"}
                    fixed={true}
                    fixedPosition={"right"}
                    width={110}
                    visible={!Config.isMobile}
                    cellRender={this.renderButtonAction}
                />
            </GridContainer>
        )
    }
}


export default connect((state) => ({ getManualRightPanel: state.W09F9000.getManualRightPanel }))(W09F9000ManualRight);

