
import React                             from "react";
import {Tooltip, Typography, withStyles} from "@material-ui/core";
import UserImage                         from "../../../common/user/user-image";
import "./W09F4000.scss";
const styles = {
    wrapperItem:       {
        position:     'relative',
        border:       '1px solid #FFFFFF',
        boxShadow:    '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        minWidth:     200,
        minHeight:    160,
        width:        200,
        backgroundColor: '#FFFFFF',
    },
    headerItem:        {
        backgroundColor: '#EAEAEC',
        borderTop:       "4px solid #111D5E",
        padding:         "18px 15px",
        height:          60,
        borderRadius:    "4px 4px 0 0"
    },
    footerItem:        {
        backgroundColor: '#FFFFFF',
        textAlign:       'center',
        padding:         '32px 15px 15px 15px',
        display:         'flex',
        justifyContent:  'center',
        alignItems:      'center'
    },
    layerImg:          {
        top:       25,
        left:      '50%',
        transform: 'translate(-50%, 0)',
        zIndex:    1,
        position:  'absolute',
    },
    userImageChild:    {
        cursor:                "pointer",
        border:                '1px solid white',
        boxShadow:             '1px 1px 2px #999',
        "&:not(:first-child)": {
            marginLeft: -8
        }
    },
    nodeNumber:        {
        position:     'absolute',
        bottom:       '-10px',
        minWidth:        '24px',
        height:       '24px',
        zIndex:       1,
        borderRadius: '50%',
        background:   "#111D5E",
        lineHeight:   '24px',
        color:        '#FFF',
        cursor:       "pointer",
        "&.nodeRed":  {
            // background: theme.palette.danger.main,
        }
    },
    styleOrgChartName: {
        fontSize:      14,
        fontWeight:    700,
        color:         "#FFFFFF",
        textTransform: "uppercase",
        overflow:      "hidden",
        whiteSpace:    "nowrap",
        textOverflow:  "ellipsis",
    },
    styleTooltip:      {
        fontSize: 14
    },
    iconNumberText:    {
        width:          32,
        height:         32,
        zIndex:         100,
        border:         "1px solid #FFFFFF",
        borderRadius:   "50%",
        background:     "#7F828E",
        display:        "flex",
        justifyContent: "center",
        alignItems:     "center"
    },
};

class ItemPositionChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            tab: 0,
            showW09F4001Popup: false,
            itemDataNode: null,
            expanded: props.data ? props.data.expanded : true,
            isHiddenW09F4010: false,
            open: false,
            hoverImg: false
        };
        this.userImage = {};
    }

    showPopup = () => {
        const {data, onW09F4001Popup} = this.props;
        if (onW09F4001Popup && data && data.DutyGroup && data.DutyGroup.length > 0) onW09F4001Popup(data);
    };

    toggleShowItem = (e) => {
        e.stopPropagation();
        const {node, data, orgchart} = this.props;
        if (orgchart && data.countChildren > 0) {
            const nodeState = orgchart._getNodeState(node, "children");

            if (nodeState.exist && nodeState.visible) {
                orgchart.hideChildren(node);
                this._scrollToNode(node, orgchart);
            } else {
                orgchart.showChildren(node);
                this._scrollToNode(node, orgchart, 400);
            }
            this.setState({expanded: !this.state.expanded});
        }
    };

    _scrollToNode = (node, orgchart) => {
        const wrapperChart = orgchart.closest("div");
        if (wrapperChart && wrapperChart.offsetWidth && node) {
            setTimeout(() => {
                let lastTf = window.getComputedStyle(orgchart).transform;
                let containerPartWidth = orgchart.offsetWidth/2 - 100;
                let containerPartHeight = orgchart.offsetHeight/2 - 180;

                let newX = 0, newY = 0;
                if (lastTf === 'none') {
                    orgchart.style.transform = 'matrix(1, 0, 0, 1, 0, 0)';
                    newX = containerPartWidth - node.offsetLeft;
                    newY = containerPartHeight - node.offsetTop;
                    if (!lastTf.includes('3d')) {
                        orgchart.style.transform = 'matrix(1, 0, 0, 1, ' + newX + ', ' + newY + ')';
                    } else {
                        orgchart.style.transform = 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ' + newX + ', ' + newY + ', 0, 1)';
                    }
                } else {
                    let matrix = lastTf.split(',');
                    newX = containerPartWidth - node.offsetLeft;
                    newY = containerPartHeight - node.offsetTop;
                    if (!lastTf.includes('3d')) {
                        matrix[4] = newX * matrix[3];
                        matrix[5] = newY + ')';
                    } else {
                        matrix[12] = newX * matrix[3];
                        matrix[13] = newY;
                    }
                    orgchart.style.transform = matrix.join(',');
                }
            }, 700);
        }
    };
    
    componentDidMount() {
        if (this.userImage) {
            Object.keys(this.userImage).forEach(u => {
                this.userImage[u].userImage.addEventListener("mouseover", () => this.onMouseOver(this.userImage[u].userImage));
                this.userImage[u].userImage.addEventListener("mouseleave", () => this.onMouseLeave(this.userImage[u].userImage));
            });
            //     u.userImage.addEventListener("mouseleave", () => this.onMouseLeave(u.userImage));
            // });
        }
    }
    
    onMouseOver = (e) => {
        e.classList.add("w09f4000-user-image-hover-item");
    };
    onMouseLeave = (e) => {
        e.classList.remove("w09f4000-user-image-hover-item");
    };

    renderItemHover = (user) => {
        return (
             <div>
                {user  && <span>{`${user.UserName} - ${user.DutyName}`}<br/></span>}
            </div>
        )
    };

    render() {
        const  {classes, data} = this.props;
        const { expanded } = this.state;
        const DutyGroup = data && data.DutyGroup ? data.DutyGroup : [];

        return (
            <>
                <div>
                    <div className={classes.wrapperItem}>
                        {/*Title*/}
                        <div className={classes.headerItem}>
                            <Tooltip
                                title={data?.OrgChartName || ""}
                                classes={{
                                    tooltip: classes.styleTooltip,
                                }}
                                placement={'top'}>
                                <Typography className={classes.styleOrgChartName}>{data.OrgChartName}</Typography>
                            </Tooltip>
                        </div>
                        {/*Content*/}
                        <div>
                            <div className={classes.layerImg} style={{marginBottom: 7}}>
                                {DutyGroup.length > 0 &&
                                <UserImage allowHover={DutyGroup && DutyGroup.length > 5}
                                           data={DutyGroup[0]}
                                           style={{margin: 0, boxShadow: "0px 4px 7px 1px #C4C4C4"}}
                                           renderItemHover={this.renderItemHover}
                                           width={72}
                                           height={72}/>
                                }
                            </div>
                        </div>
                        <div className={classes.footerItem}>
                            {DutyGroup.length > 1 && <div style={{display:"flex", margin: "18px 4px 10px 4px"}}>
                                {DutyGroup.map((val,indx) => {
                                    if (indx > 6) return null;
                                    if(indx >= 1 && indx < 6) {
                                        return (
                                            <UserImage key={indx}
                                                       allowHover={true}
                                                       data={val}
                                                       width={32}
                                                       style={{
                                                           marginRight: 0,
                                                           zIndex: indx
                                                       }}
                                                       ref={ref => this.userImage["image" + indx] = ref}
                                                       className={"w09f4000-user-image " + classes.userImageChild}
                                                       renderItemHover={this.renderItemHover}
                                                       height={32}/>

                                        )
                                    } else if (indx === 6) {
                                        return (
                                            <div key={indx} className={classes.iconNumberText + " " + classes.userImageChild} onClick={this.showPopup}>
                                                <div style={{fontSize:10,color:"#FFFFFF"}}>
                                                    <i className="fas fa-plus" />&nbsp;
                                                    <span style={{fontSize: 12}}>{DutyGroup?.length - indx}</span>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>}
                            {DutyGroup.length === 1 && <div className={"display_col align-center valign-center"}
                                                          style={{margin: "18px 4px 10px 4px"}}>
                                <Typography style={{
                                    fontSize:   14,
                                    fontWeight: 500,
                                    color:      '#151A30'
                                }}>{DutyGroup[0].UserName || ""}</Typography>
                                <Tooltip
                                    title={DutyGroup[0].DutyName  || ""}
                                    classes={{
                                        tooltip: classes.styleTooltip,
                                    }}
                                    placement={'top'}>
                                    <Typography className={"textHidden"}>{DutyGroup[0].DutyName || ""}</Typography>
                                </Tooltip>
                            </div>}
                            {DutyGroup.length > 0 && data.countChildren > 0 && <div onClick={this.toggleShowItem}
                                                             className={classes.nodeNumber + (expanded ? " nodeIconRed" : "")}>
                                <div> {!expanded ? data?.countChildren : (data?.countChildren > 0 &&
                                    <i className="fas fa-minus"/>)}</div>
                            </div>}
                        </div>
                    </div>
                </div>
                <i className="edge verticalEdge topEdge fa" />
                <i className="edge horizontalEdge rightEdge fa" />
                <i className="edge horizontalEdge leftEdge fa" />
                <i className="edge verticalEdge bottomEdge fa" />
            </>
        );
    }

}

export default withStyles(styles, {withTheme: true})(ItemPositionChart);
