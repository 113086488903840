import React, { Component } from "react";
import {  Col, Row } from "react-bootstrap";
import {  TextField } from "../../../common/form-material";
import Config from "../../../../config";
import _ from "lodash";
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
const styles = theme => {
  return{
    view:{
      // [theme.breakpoints.down("xs")]: {
      //   '& .col-xs-12':{
      //     width: '100% !important'
      //   }
      // },
      // [theme.breakpoints.down("md")]: {
      //   '& .col-md-2':{
      //     width:'20%',
      //   },
      //   '& .col-md-6':{
      //     width:'60%'
      //   },
      // },
      // [theme.breakpoints.down("sm")]: {
      //   '& .col-sm-6':{
      //     width: '50%'
      //   },
      //   '& .col-sm-12':{
      //     width: '100%'
      //   },
      // },
      // [theme.breakpoints.up("lg")]: {
      //   '& .col-lg-2':{
      //     width: '20%'
      //   },
      //   '& .col-lg-6':{
      //     width: '60%'
      //   },
      // },
    },
  }
};
class W21F2005FormDetail extends Component {
  render() {
    const { employeeSelected } = this.props;
    const { classes } = this.props;
    return (
        <div className={classes.view}>
          <Row>
            <Col xs={12} md={3} sm={6}>
              <TextField
                  error={false}
                  label={Config.lang("DHR_Nhan_vien")}
                  variant={"standard"}
                  margin={"normal"}
                  disabled={false}
                  value={_.get(employeeSelected, "EmployeeName","")}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
              />
            </Col>
            <Col xs={12} md={4} sm={6}>
              <TextField
                  error={false}
                  label={Config.lang("DHR_So_so_bhxh")}
                  variant={"standard"}
                  margin={"normal"}
                  disabled={false}
                  value={_.get(employeeSelected, "SocInsBookNo","")}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
              />
            </Col>
            <Col xs={12} md={3} sm={6}>
              <TextField
                  error={false}
                  label={Config.lang("DHR_Luong_dong_bao_hiem")}
                  variant={"standard"}
                  margin={"normal"}
                  disabled={false}
                  value={_.get(employeeSelected, "SIAmount", "").toLocaleString().toString()}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
              />
            </Col>
            <Col xs={12} md={2} sm={6}>
              <TextField
                  error={false}
                  label={Config.lang("DHR_So_ngay_nghi_trong_ky")}
                  variant={"standard"}
                  margin={"normal"}
                  disabled={false}
                  value={_.get(employeeSelected, "DayOffTotal", "").toString()}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
              />
            </Col>
    
            <Col xs={12} md={3} sm={6}>
              <TextField
                  error={false}
                  label={Config.lang("DHR_Phong_ban")}
                  variant={"standard"}
                  margin={"normal"}
                  disabled={false}
                  value={_.get(employeeSelected, "DepartmentName", "")}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
              />
            </Col>
            <Col xs={12} md={4} sm={6}>
              <TextField
                  error={false}
                  label={Config.lang("DHR_Che_do_tro_cap")}
                  variant={"standard"}
                  margin={"normal"}
                  disabled={false}
                  value={_.get(employeeSelected, "GrantTypeName", "") || ""}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
              />
            </Col>
            <Col xs={12} md={3} sm={6} >
              <TextField
                  error={false}
                  label={Config.lang("DHR_Tien_tro_cap")}
                  variant={"standard"}
                  margin={"normal"}
                  disabled={false}
                  value={_.get(employeeSelected, "ApprovedAmount", "").toLocaleString().toString()}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
              />
            </Col>
            <Col xs={12} md={2} sm={6}>
              <TextField
                  error={false}
                  label={Config.lang("DHR_So_ngay_da_nghi")}
                  variant={"standard"}
                  margin={"normal"}
                  disabled={false}
                  value={_.get(employeeSelected, "UsedQuantityDays", "").toString() || ""}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
              />
            </Col>
     
            <Col xs={12} md={3} sm={6}>
              <TextField
                  error={false}
                  label={Config.lang("DHR_Chuc_danh")}
                  variant={"standard"}
                  margin={"normal"}
                  disabled={false}
                  value={_.get(employeeSelected, "PositionName", "") || ""}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
              />
            </Col>
            <Col xs={12} md={4} sm={6}>
              <TextField
                  error={false}
                  label={Config.lang("DHR_Dieu_kien_tinh_huong")}
                  variant={"standard"}
                  margin={"normal"}
                  disabled={false}
                  value={_.get(employeeSelected, "ReceivingConditionName", "") || ""}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
              />
            </Col>
            <Col xs={12} md={3} sm={6} >
              <TextField
                  error={false}
                  label={Config.lang("DHR_Tong_so_ngay_duoc_nghi")}
                  variant={"standard"}
                  margin={"normal"}
                  disabled={false}
                  value={_.get(employeeSelected, "SumQuantityDays", "").toString() || ""}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
              />
            </Col>
            <Col xs={12} md={2} sm={6} >
              <TextField
                  error={false}
                  label={Config.lang("DHR_So_ngay_con_lai")}
                  variant={"standard"}
                  margin={"normal"}
                  disabled={false}
                  value={_.get(employeeSelected, "RemainedQuantityDays", "").toString() || ""}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
              />
            </Col>
            <Col xs={12}>
              <TextField
                  error={false}
                  label={Config.lang("DHR_Ghi_chu")}
                  variant={"standard"}
                  margin={"normal"}
                  disabled={false}
                  value={_.get(employeeSelected, "Note", "") || ""}
                  inputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
              />
            </Col>
          </Row>
        </div>
    );
  }
}
export default compose(
    connect(),
    withStyles(styles,{withTheme: true})
)(W21F2005FormDetail);
