/**
 * @copyright 2019 @ DigiNet
 * @author ANHTAI   
 * @create 5/8/2020
 * @Example
 */
import { FormLabel as Label, Icon, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import { Column } from "devextreme-react/data-grid";
import { Col, FormGroup, Row } from "react-bootstrap";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import ApprovalsPopover from "../../../approvals/approvalspopover";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W21F2010Actions from "../../../../redux/W2X/W21F2010/W21F2010_actions";
import ButtonGeneral from "../../../common/button/button-general";
import { Combo } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Filter from "../../../filter/filter";
import GridContainer from "../../../grid-container/grid-container";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Status from "../../../common/status/status";

import _ from "lodash";
const drawerWidth = '47%';
const styles = theme => {
    return {
        divAvatar: {
            width: 46,
            height: 46,
            borderRadius: '50%',
            overflow: 'hidden',
            marginRight: 8
        },
        imgAvatar: {
            maxWidth: '100%',
            maxHeight: '100%',
            height: '100%',
            objectFit: 'cover'
        },
        drawer: {
            width: drawerWidth,
            maxWidth: 700,
            flexShrink: 0,
            [theme.breakpoints.down('sm')]: {
                zIndex: '1502 !important',
            },
        },
        panel: {
            backgroundColor: "#FFF",
            padding: 15,
            overflowY: Config.isMobile ? 'auto' : 'hidden',
            '&:hover': {
                overflowY: 'auto'
            }
        },
        content: {
            transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'
        },
        contentDrawer: {
            width: '50%'
        },
        iconGreen: {
            "&:disabled": {
                color: '#FFF',
                backgroundColor: theme.palette.success.main,
            }
        },
        iconHoverGreen: {
            '&:hover': {
                color: theme.palette.primary.main,
            },
        }
    }
};

class W21F2010 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            FormID: "W21F2010",
            TransID: "",
            iPermission: 0,
            dataForm: {},
            Language: 84,
            confirming: false,
            gridLoading: false,
            mode: 0
        };
        this.filter = {
            Search: "",
            AppStatusID: "",
            DateFrom: null,
            DateTo: null,
            skip: 0,
            limit: 10
        };
        this.refApprovalPopover = null;
    }

    componentDidMount = async () => {
        const { dataSource } = this.props;
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadCboStatus();
        if (!dataSource) {
            this.loadGrid();
        }
    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W21F2010", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    loadCboStatus = () => {
        const { FormID } = this.state;
        const params = {
            FormID,
            Language: Config.language || '84'
        };
        this.props.w21f2010Actions.getCboStatus(params, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadGrid = () => {
        const { FormID, TransID, mode } = this.state;
        const { AppStatusID, DateFrom, DateTo, skip, limit } = this.filter;
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID,
            Language: Config.language || '84',
            mode,
            skip: skip,
            limit: limit,
            AppStatusID,
            TransID,
            DateFrom,
            DateTo
        };
        this.setState({ gridLoading: true });
        this.props.w21f2010Actions.getGridConfirm(params, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ gridLoading: false });
        });
    };

    onReset = (resetData) => {
        this.setState({
            open: false,
            selectedRow: null,
        }, () => {
            this.panelLeft.style.width = '100%';
            if (resetData) {
                this.loadGrid();
            }
        });
    };

    handleFilterChange = (key, e) => {
        const { onTextFilterChanged } = this.props;
        switch (key) {
            case "Search":
                this.filter.Search = e.target.value;
                if (onTextFilterChanged) {
                    if (this.timer) clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        onTextFilterChanged(this.filter);
                    }, 500);
                }
                break;
            case "AppStatusID":
                this.filter.AppStatusID = e.value;
                break;
            case "DateFrom":
                this.filter.DateFrom = e.value;
                break;
            case "DateTo":
                this.filter.DateTo = e.value;
                break;
            default:
                break;
        }
    };

    onFilter = () => {
        const { filterRender, onFilterChanged } = this.props;
        this.onReset();
        if (!filterRender) {
            this.loadGrid();
        }
        if (onFilterChanged) onFilterChanged(this.filter);
        if (this.toolFilter) {
            this.toolFilter.instance.close();
        }
    };

    onOpenApproval = (type, e, data) => {
        this.setState({
            anchorEl: e.currentTarget,
            type: type,
            selectedRow: data
        }, () => {
            if (this.refApprovalPopover) this.refApprovalPopover.onOpenPopover();
        });
    };

    onClosePopoverApproval = () => {
        this.setState({
            selectedRow: null,
        })
    };

    renderItem = (e) => {
        const { data } = e.row;
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} />
                <span>
                    <div>{Config.lang("Nhan_vien")}: <UserName data={data} /></div>
                    <div>{Config.lang("So_dien_thoai")}: {data.Telephone}</div>
                    <div>{Config.lang("Du_An")}: {data.ProjectName}</div>
                </span>
            </div>
        );
    };

    renderInformation = (e) => {
        const { data } = e.row;
        return (
            <div className={"display_row align-center pdt10 pdb10"} style={{ lineHeight: '24px' }}>
                <div className={"confirm-description"}>
                    <b>{Config.lang("Noi_dung")}: </b>{data.Content}<br />
                    <b>{Config.lang("So_the_bao_hiem_y_te")}: </b>{data.HealthInsBookNo}<br />
                    <b>{Config.lang("So_so_bao_hiem_xa_hoi")}: </b>{data.SocInsBookNo}<br />
                </div>
            </div>
        );
    };

    // _getStatusInfo = (ConfirmStatus) => {
    //     const status = ConfirmStatus ? ConfirmStatus : 0;
    //     const { getCboStatus } = this.props;
    //     const color = Number(status) === 1 ? "primary" : "default";
    //     const itemStatus = getCboStatus.filter((e) => {
    //         return Number(e.ApprovalStatus) === Number(status);
    //     });
    //     return {
    //         color: color,
    //         ConfirmStatus: status,
    //         ConfirmStatusName: itemStatus && itemStatus.length > 0 ? itemStatus[0].AppStatusName : Config.lang("Khong_trang_thai")
    //     };
    // };

    renderStatus = (e) => {
        const { data } = e.row;
        return <Status data={data} />;
    };

    renderAction = (e) => {
        const { classes, labelBtnConfirm } = this.props;
        const { data } = e.row;
        return (
            <div className={"display_row align-center"} style={{ minHeight: 32 }}>
                <Tooltip title={labelBtnConfirm ? labelBtnConfirm : Config.lang(Number(data.AppStatusID) >= 1 ? "Da_xac_nhan" : "Xac_nhan")}
                    aria-label={labelBtnConfirm ? labelBtnConfirm : Config.lang("Xac_nhan")}>
                    <span>
                        <IconButton aria-label="view" size="small"
                            disabled={(data && (Number(4) <= 2 || Number(data.AppStatusID) >= 1))}
                            className={Number(data.AppStatusID) >= 1 ? classes.iconGreen : classes.iconHoverGreen}
                            onClick={(e) => this.onOpenApproval("confirm", e, data)}>
                            <Icon className="fas fa-check" fontSize={"small"} />
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        )
    };

    renderFilters = () => {
        const { getCboStatus } = this.props;
        return (
            <Filter
                dropdownProps={{
                    ref: ref => this.toolFilter = ref
                }}
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            dataSource={getCboStatus}
                                            showClearButton={true}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"ApprovalStatus"}
                                            value={this.filter.AppStatusID}
                                            stylingMode={"outlined"}
                                            label={Config.lang("Trang_thai_duyet")}
                                            onValueChanged={e => this.handleFilterChange("AppStatusID", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("Tu")}</Label>
                                            <DateBoxPicker
                                                max={this.filter.DateTo}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                defaultValue={moment().startOf("week")}
                                                width={"100%"}
                                                value={this.filter.DateFrom}
                                                onValueChanged={e => this.handleFilterChange("DateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("Den")}</Label>
                                            <DateBoxPicker
                                                min={this.filter.DateFrom}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                defaultValue={moment().endOf("week")}
                                                width={"100%"}
                                                value={this.filter.DateTo}
                                                onValueChanged={e =>
                                                    this.handleFilterChange("DateTo", e)
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral name={Config.lang('Tim_kiem')}
                                    typeButton={'search'}
                                    color={"primary"}
                                    className={"pdl10 pdr10"}
                                    variant={"outlined"}
                                    style={{ textTransform: 'uppercase' }}
                                    size={"large"}
                                    onClick={this.onFilter} />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    saveConfirm = (Status, Notes, arrIsBeingAttached) => {
        const { selectedRow, Language, FormID } = this.state;
        const { DateFrom, DateTo, skip, limit } = this.filter;
        if (!selectedRow || !selectedRow.TransID) {
            Config.popup.show('INFO', Config.lang("Khong_the_duyet"));
            return false;
        }
        this.setState({ confirming: true });
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID,
            ApproveNotes: Notes ? Notes : "",
            AppStatusID: Status ? Status : 1,
            TransID: selectedRow.TransID ? selectedRow.TransID : "",
            DateFrom,
            DateTo,
            Language,
            mode: 1,
            arrAttachment: JSON.stringify(arrIsBeingAttached),
            skip,
            limit
        };

        this.props.w21f2010Actions.saveConfirm(params, (error) => {
            this.setState({ confirming: false });
            if (error) {
                let message = Config.lang("Loi_chua_xac_dinh");
                switch (error.code) {
                    case "F1002E007":
                        message = params.ConfirmStatus === 1 ? Config.lang("Duyet_khong_thanh_cong") : "";
                        break;
                    default:
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }

            this.onReset(true);
            Config.notify.show('success', Config.lang("Thuc_hien_thanh_cong"), 2000);
        });
    };

    onConfirm = (notes, arrIsBeingAttached = []) => {
        const { onConfirm } = this.props;
        if (onConfirm) {
            onConfirm(notes);
        } else {
            this.saveConfirm(1, notes, arrIsBeingAttached);
        }
    };
    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();

    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadGrid();
    };
    render() {
        const { open, iPermission,
            anchorEl, type, selectedRow, confirming, gridLoading
        } = this.state;
        const { classes, loading, getGridConfirm, disabled,
            dataSource, labelBtnConfirm, showFilters
        } = this.props;
        if (!iPermission) return null;
        const arrHasBeenAttached = getGridConfirm?.rows ? getGridConfirm.rows.filter((item) => item.TransID === _.get(selectedRow, "TransID", "")).map((item) => ({ arrAttachment: item.arrAttachment, AppStatusID: item.AppStatusID })) : [];

        return (
            <FormGroup>
                <ActionToolbar title={Config.lang("Xac_nhan_tra_the_so_bao_hiem")}></ActionToolbar>
                <div className={"hidden"}>{this.renderFilters()}</div>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <React.Fragment>
                                <ApprovalsPopover
                                    ref={ref => this.refApprovalPopover = ref}
                                    onClose={this.onClosePopoverApproval}
                                    loading={confirming}
                                    anchorEl={anchorEl}
                                    type={type}
                                    labelBtnConfirm={labelBtnConfirm}
                                    onConfirm={this.onConfirm}
                                    arrHasBeenAttached={arrHasBeenAttached}
                                />
                                <div style={{ position: 'relative' }}>
                                    <div ref={ref => this.panelLeft = ref} className={classes.content + ' ' + (open ? classes.contentDrawer : "")}>
                                        <div>
                                            <GridContainer
                                                style={{ border: 'none' }}
                                                totalItems={dataSource && dataSource.total ? dataSource.total : (getGridConfirm && getGridConfirm.total ? getGridConfirm.total : 0)}
                                                dataSource={dataSource && dataSource.rows ? dataSource.rows : (getGridConfirm ? getGridConfirm.rows : [])}
                                                loading={gridLoading || loading}
                                                disabled={disabled}
                                                itemPerPage={this.filter.limit}
                                                skipPerPage={this.filter.skip}
                                                showBorders={false}
                                                height={Config.getHeightGrid() - (showFilters || typeof showFilters === "undefined" ? 75 : 0)}
                                                showColumnLines={false}
                                                showColumnHeaders={false}
                                                hoverStateEnabled={true}
                                                typePaging={"remote"}
                                                noDataText={Config.lang("Khong_co_du_lieu")}
                                                selectedRowKey={[selectedRow]}
                                                onChangePage={this.onChangePage}
                                                onChangePerPage={this.onChangePerPage}
                                            >
                                                <Column
                                                    dataField={'Name'}
                                                    minWidth={300}
                                                    cellRender={this.renderItem}
                                                />
                                                <Column
                                                    dataField={'Information'}
                                                    minWidth={300}
                                                    cellRender={this.renderInformation}
                                                />
                                                <Column
                                                    dataField={'ConfirmStatus'}
                                                    cellRender={this.renderStatus}
                                                    alignment={"center"}
                                                    minWidth={100}
                                                    visible={!open}
                                                />
                                                <Column
                                                    minWidth={100}
                                                    dataField={"Action"}
                                                    cellRender={this.renderAction}
                                                    visible={!open}
                                                />
                                            </GridContainer>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        </Col>
                    </Row>
                </FormGroup>
            </FormGroup>
        )
    }
}

export default compose(
    connect(
        state => ({
            getCboStatus: state.W21F2010.getCboStatus,
            getGridConfirm: state.W21F2010.getGridConfirm
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w21f2010Actions: bindActionCreators(W21F2010Actions, dispatch)
        }), null, { forwardRef: true }
    ),
    withStyles(styles, { withTheme: true })
)(W21F2010);