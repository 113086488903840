
import React                             from "react";
import {Tooltip, Typography, withStyles} from "@material-ui/core";
import UserImage                         from "../../../common/user/user-image";
const styles = {
    wrapperItem: {
        position:'relative',
        border: '1px solid #FFFFFF',
        boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 4,
        minWidth:200,
        minHeight:160,
        width: 200,
        backgroundColor: '#FFFFFF',
    },
    headerItem: {
        backgroundColor: '#111D5E',
        padding: "18px 15px",
        height: 60,
        borderRadius: "4px 4px 0 0"
    },
    footerItem: {
        backgroundColor: '#FFFFFF',
        textAlign: 'center',
        padding: '32px 32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    layerImg: {
        top: 45,
        left: '50%',
        transform: 'translate(-50%, 0)',
        zIndex: 1,
        position: 'absolute',
    },
    nodeNumber: {
        position: 'absolute',
        bottom: '-10px',
        minWidth: '24px',
        height: '24px',
        zIndex: 1,
        borderRadius: '50%',
        background: '#111D5E',
        lineHeight: '24px',
        color: '#FFF',
        cursor: "pointer"
    },
    styleOrgChartName: {
        fontSize:14,
        fontWeight:700,
        color:"#FFFFFF",
        textTransform:"uppercase",
        display: "inline-flex"
    },
    styleOverFlowText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    styleTooltip: {
        fontSize: 14
    },
    cursorPointer: {
        cursor: "pointer"
    }

};

class ItemOrgChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            iPermission: 0,
            tab: 0,
            showW09F4001Popup: false,
            itemDataNode: null,
            expanded: props.data ? props.data.expanded : true,
            isHiddenW09F4010: false
        };
    }

    showPopup = () => {
        const {data, onW09F4001Popup} = this.props;
        if (onW09F4001Popup && data?.Quantity > 0) onW09F4001Popup(data);
    };

    toggleShowItem = (e) => {
        e.stopPropagation();
        const {node, data, orgchart} = this.props;
        if (orgchart && data.countChildren > 0) {
            const nodeState = orgchart._getNodeState(node, "children");

            if (nodeState.exist && nodeState.visible) {
                orgchart.hideChildren(node);
                this._scrollToNode(node, orgchart);
            } else {
                orgchart.showChildren(node);
                this._scrollToNode(node, orgchart, 400);
            }
            this.setState({expanded: !this.state.expanded});
        }
    };

    _scrollToNode = (node, orgchart, timeout = 700) => {
        const wrapperChart = orgchart.closest("div");
        if (wrapperChart && wrapperChart.offsetWidth && node) {
            setTimeout(() => {
                let lastTf = window.getComputedStyle(orgchart).transform;
                let containerPartWidth = orgchart.offsetWidth/2 - 100;
                let containerPartHeight = orgchart.offsetHeight/2 - 220;

                let newX = 0, newY = 0;
                if (lastTf === 'none') {
                    orgchart.style.transform = 'matrix(1, 0, 0, 1, 0, 0)';
                    newX = containerPartWidth - node.offsetLeft;
                    newY = containerPartHeight - node.offsetTop;
                    if (!lastTf.includes('3d')) {
                        orgchart.style.transform = 'matrix(1, 0, 0, 1, ' + newX + ', ' + newY + ')';
                    } else {
                        orgchart.style.transform = 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ' + newX + ', ' + newY + ', 0, 1)';
                    }
                } else {
                    let matrix = lastTf.split(',');
                    newX = containerPartWidth - node.offsetLeft;
                    newY = containerPartHeight - node.offsetTop;
                    if (!lastTf.includes('3d')) {
                        matrix[4] = newX * matrix[3];
                        matrix[5] = (newY * matrix[3])  + ')';
                    } else {
                        matrix[12] = newX * matrix[3];
                        matrix[13] = (newY * matrix[3]);
                    }
                    orgchart.style.transform = matrix.join(',');
                }
            }, 700);
        }
    };

    render() {
        const  {classes, data} = this.props;
        const { expanded } = this.state;

        return (
            <>
                <div>
                    <div className={classes.wrapperItem + (data?.countChildren > 0 ? " " + classes.cursorPointer : "")}
                         onClick={this.showPopup}>
                        {/*Title*/}
                        <div className={classes.headerItem}>
                            <Tooltip
                                title={`${data?.OrgChartName || ""}  ${!isNaN(data?.Quantity) ? "("+data?.Quantity+")" : ""}`}
                                classes={{
                                    tooltip: classes.styleTooltip,
                                }}
                                placement={'top'}>
                                <Typography className={classes.styleOrgChartName}>
                                    <span className={classes.styleOverFlowText} style={{maxWidth:"130px"}}>{data?.OrgChartName || ""} </span>
                                    <span> {` ${!isNaN(data?.Quantity) ? "("+data?.Quantity+")" : ""}`} </span>
                                   </Typography>
                            </Tooltip>
                            <div className={classes.layerImg} style={{marginBottom: 7}}>
                                {data &&
                                <UserImage allowHover={true}
                                           style={{marginRight: 0}}
                                           data={data}
                                           width={48}
                                           height={48}/>
                                }
                            </div>
                        </div>
                        {/*Content*/}
                        <div className={classes.footerItem}>
                            <div className={"display_col align-center valign-center mgt15"}>
                                <Typography style={{
                                    fontSize:   14,
                                    fontWeight: 500,
                                    color:      '#151A30'
                                }}>{data.ManagerUserName || ""}</Typography>
                                <Tooltip
                                    title={data.DutyName || ""}
                                    classes={{
                                        tooltip: classes.styleTooltip,
                                    }}
                                    placement={'top'}>
                                    <Typography className={"textHidden"}>{data.DutyName || ""}</Typography>
                                </Tooltip>

                            </div>
                            {data?.countChildren > 0 && <div onClick={this.toggleShowItem}
                                 className={classes.nodeNumber}>
                                <div> {!expanded ? data?.countChildren : (data?.countChildren > 0 &&
                                    <i className="fas fa-minus"/>)}</div>
                            </div>}
                        </div>
                    </div>
                </div>
                <i className="edge verticalEdge topEdge fa" />
                <i className="edge horizontalEdge rightEdge fa" />
                <i className="edge horizontalEdge leftEdge fa" />
                <i className="edge verticalEdge bottomEdge fa" />
            </>
        );
    }

}

export default withStyles(styles, {withTheme: true})(ItemOrgChart);
