/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 1/8/2020
 * @Example
 */
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup, Image} from "react-bootstrap"
import withStyles from "@material-ui/core/styles/withStyles";
import Approvals from "../../../approvals/approvals";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import LabelText from "../../../common/label-text/label-text";
import InlineSVG from "react-inlinesvg";
import {FormControl, Input, InputLabel} from "@material-ui/core";
import * as generalActions from "../../../../redux/general/general_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain'
    },
};
class W13F1000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            dataForm: {},
            VoucherID: "",
            loading: false,
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W13F1000", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadFormInfo = (VoucherID, cb) => {
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: "W13F1000",
            Language: Config.language || "84",
            VoucherID: VoucherID ? VoucherID : "",
        };
        this.setState({loading: true})
        this.props.approvalActions.getFormInfo(params, (error, data) => {
            this.setState({loading: false})
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    dataForm: data
                });
                cb && cb (data);
            }
        });
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        // this.checkNotify();
    };

    // UNSAFE_componentWillReceiveProps = () => {
    //     this.checkNotify();
    // };
    //
    // checkNotify = () => {
    //     const {VoucherID} = this.getInfo();
    //     this.setState({VoucherID: VoucherID});
    //     // if(VoucherID && !this.voucher_id){
    //     //     this.voucher_id = VoucherID;
    //     //     this.loadFormInfo(VoucherID,(data)=>{
    //     //         this.voucher_id=null;
    //     //         data.VoucherID=VoucherID;
    //     //         // this.refs['Approvals'].showPopup(data);
    //     //     });
    //     // }
    // };
    //
    // getInfo = () => {
    //     const {location} = this.props;
    //     const url = new window.URLSearchParams(window.location.search);
    //     if (url && url.get('voucher_id')) {
    //         return {VoucherID: url.get('voucher_id')};
    //     } else if (location && location.state) {
    //         return {
    //             VoucherID: location.state.voucher_id,
    //         }
    //     } else {
    //         return {};
    //     }
    // };

    //Form info..
    renderFormInfo = (data) => {
        const {classes} = this.props;
        return (
            <>
                <FormGroup>
                    <LabelText
                        label={Config.lang("DHR_Nhan_vien")}
                        allowPadding={true}
                        value={() => {
                            return (
                                <div className={"display_row align-center pdb5"} style={{width: "100%", height: 37}}>
                                    <div className={classes.divAvatar}>
                                        {data && data.UserPictureURL ? (
                                            <Image className={classes.imgAvatar} src={data.UserPictureURL}/>
                                        ) : (
                                            <InlineSVG className={classes.imgAvatar}
                                                       src={require('../../../../assets/images/general/user_default.svg')}/>
                                        )}
                                    </div>
                                    <Input
                                        color={"primary"}
                                        readOnly={true}
                                        value={data && data.EmployeeName ? data.EmployeeName : ""}
                                        disableUnderline={true}
                                    />
                                    {/*<div style={{padding: '7px 0'}}>{data && data.EmployeeName ? data.EmployeeName : ""}</div>*/}
                                </div>
                            );
                        }}
                        fullWidth={true}
                    />
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        <Col xs={12} sm={12} md={8} lg={8} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Du_an")}
                                value={Config.helpers.getObjectValue(data, 'ProjectName', '')}
                                fullWidth={true}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} className={"mgb15"}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ky_luong")}
                                value={Config.helpers.getObjectValue(data, 'Period', '')}
                                fullWidth={true}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            <FormControl fullWidth={true}>
                                <InputLabel shrink={true} focused={false} style={{fontSize: '14px',fontWeight: 200}}>
                                    {Config.lang("DHR_Loai_thu_nhap")}
                                </InputLabel>
                                {data && data.AbsentType && data.AbsentType.length > 0 ? (
                                    data.AbsentType.map((e, indx) => {
                                        return (
                                            <Row key={indx} style={indx === 0 ? {marginTop: 20} : null}>
                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                    <LabelText
                                                        allowPadding={true}
                                                        value={Config.helpers.getObjectValue(e, 'AbsentTypeName', '')}
                                                        fullWidth={true}
                                                    />
                                                </Col>
                                            </Row>
                                        )
                                    })
                                ) : (
                                    <Row style={{marginTop: 20}}>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <LabelText
                                                allowPadding={true}
                                                value={""}
                                                fullWidth={true}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </FormControl>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4}>
                            <FormControl fullWidth={true}>
                                <InputLabel shrink={true} focused={false} style={{fontSize: '14px',fontWeight: 200}}>
                                    {Config.lang("DHR_So_luong")}
                                </InputLabel>
                                {data && data.AbsentType && data.AbsentType.length > 0 ? (
                                    data.AbsentType.map((e, indx) => {
                                        return (
                                            <Row key={indx} style={indx === 0 ? {marginTop: 20} : null}>
                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                    <LabelText
                                                        allowPadding={true}
                                                        value={Config.helpers.getObjectValue(e, 'Number', '')}
                                                        fullWidth={true}
                                                    />
                                                </Col>
                                            </Row>
                                        )
                                    })
                                ) : (
                                    <Row style={{marginTop: 20}}>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <LabelText
                                                allowPadding={true}
                                                value={""}
                                                fullWidth={true}
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </FormControl>
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <LabelText
                        allowPadding={true}
                        label={Config.lang("DHR_Ghi_chu")}
                        value={Config.helpers.getObjectValue(data, 'Note', '')}
                        fullWidth={true}
                    />
                </FormGroup>
            </>
        );
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        this.loadFormInfo(e.VoucherID);
    };

    onDrawerClosed = () => {
        this.setState({VoucherID: ""});
    };

    render() {
        const {iPermission, dataForm, VoucherID, loading} = this.state;
        if (!iPermission) return null;

        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar title={Config.lang("DHR_Duyet_dieu_chinh_thu_nhap")}/>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Approvals
                                loading={loading}
                                parentProps={this.props}
                                FormID={"W13F1000"}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                selectedRowKey={VoucherID}
                                singleClick
                                formInfoRender={this.renderFormInfo} //render form info with every screen
                                onSelectionChanged={this.onSelectionChanged} //action when select row...
                                onDrawerClosed={this.onDrawerClosed}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect(null, (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    approvalActions: bindActionCreators(ApprovalActions, dispatch),
})), withStyles(styles))(W13F1000);
